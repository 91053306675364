import { mapState } from "vuex";
import { Dialog, Toast } from "vant";
import RetailOrderItem from "./components/retail-order-item/index.vue";
import { getToken } from "@/utils/localStorage.js";
import { getRefundList } from "@/service/enroll-activity/index.js";

export default {
    name: "aftermarket-order-list",

    components: {
        RetailOrderItem
    },

    computed: {
        ...mapState({
            userId: (state) => state.user.user.id,
        }),
    },

    data() {
        return {
            active: 0,
            tabs: [
                { name: "全部", state: '-1' },
                { name: "审核中", state: '1' },
                { name: "已退款", state: '2' },
                { name: "已拒绝", state: '3' },
            ],
            orderList: [],
        };
    },

    methods: {
        //  根据state值设置当前活跃的tab
        getActiveIndexByState(state) {
            let index = this.tabs.findIndex(
                (tab) => String(tab.state) === String(state)
            );
            if (index === -1) {
                index = 0;
            }
            this.active = index;
        },

        //  点击tab
        onClickTab() {
            //  更新一下路由
            this.$router.push({
                name: this.$route.name,
                query: { state: this.tabs[this.active].state },
            });
            this.fetchData();
        },

        //  请求订单列表
        fetchData() {
            this.orderList = [];
            const state = this.tabs[this.active].state;
            getRefundList({ userId: this.userId, state }).then((res) => {
                this.orderList = res || [];
            });
        },

        //  点击订单
        onClickOrder(order) {
            this.$router.push({
                name: "enroll-retail-order-detail",
                query: { 
                    orderId: order.code,
                    from: 'aftermarket'
                },
            });
        },

        //  发货
        onDeliveryOrder(order) {
            const { code, createdAt, juniorId } = order;
            this.$router.push({
                name: "order-logistics",
                query: { code, createdAt, from: "retail", juniorId },
            });
        },

        //  取消订单
        onCancelOrder(order) {
            Dialog.confirm({
                title: "取消订单",
                message: "确定取消该订单吗～",
            })
                .then(() => {
                    cancelOrder({ code: order.code }).then((res) => {
                        Toast("取消订单成功～");
                        this.fetchData();
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },

    created() {
        const { state } = this.$route.query;
        this.getActiveIndexByState(state);
        this.fetchData();
    },
};
