const map = {
    "1": "审核中",
    "2": "已退款",
    "3": "已拒绝",
};

//  获取订单状态
export function getAftermarketState(state) {
    return map[state];
}
